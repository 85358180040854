import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import small1 from "../images/small-1.jpg"
import small2 from "../images/small-2.jpg"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";


function LargeGroupTraining(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Large Group Training</title>
            </Helmet>
            <Nav/>
            <Hero href={"/contact"} header={"Do you have a group of 6-12 people looking to become healthier and level up TOGETHER? "}>
            <div id={"content-start"} className={"flex flex-col justify center max-w-5xl mx-auto px-8 pt-8 text-lg bg-white rounded-xl"}>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>We offer larger group training programs that make health and durability a priority, increased performance and productivity is a byproduct. </p>

                <h2 className={"text-3xl text-center py-14"}>Are you looking to build your office morale?</h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Large group training is a phenomenal outlet for corporate wellness. The best ROI for business owners is healthy, positive and motivated employees. Large group training is a great way to build morale, decrease sick days and provide a positive bonding experience for your staff. When you value your health, so do your employees and productivity will reflect it. This is sweat equity. </p>
                <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/vd-GAUj5ASU"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                    <div className={"lg:flex lg:flex-row lg:justify-center "}>
                        <a className={"px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                        <a className={"px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                    </div>
                </div>

                <h2 className={"text-3xl text-center py-14"}>Stop watching your athletes miss time on the field/court</h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Are you looking to take your athletes to the next level? We will help your athletes understand what it means to prepare like a pro with strength programs built for durability that will make your athletes stronger, run faster and jump higher as a byproduct. Set your athletes up for success by providing them with the physical qualities they need to stay healthy and excel in their sport. The best ability is availability.</p>
                <iframe className={"py-5 mx-auto w-full lg:w-auto aspect-video"} width="560" height="315" src="https://www.youtube.com/embed/xycjt12igYg"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                    <a className={"px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                    <a className={"px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                </div>


                <h2 className={"text-3xl text-center py-14"}>Moms, it is time to decompress and take some time for yourself. </h2>
                <p className={"lg:pt-4 pb-4 max-w-2xl lg:px-4 mx-auto"}>Sweat together with a group of your friends so you can get out of the house and take a breather from the kids. Make social hour a fitness hour so you can release some stress of being an uber driver and super mom. Get a group of 5-12 friends or family together and let's have some fun. Learn how to become healthier and give some well-earned time to yourself.</p>
                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                    <a className={"px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                    <a className={"px-4"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                </div>
                <h2 className={"text-3xl text-center py-14"}>Large Group Training Programs that we offer</h2>
                <div className="flex justify-center">
                    <ul className="bg-white rounded-lg w-96 text-gray-900">
                        <li className="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">8 Week Intro to Strength and Conditioning Training</li>
                        <li className="px-6 py-2 border-b border-gray-200 w-full">12 Week Strength and Conditioning Program</li>
                        <li className="px-6 py-2 border-b border-gray-200 w-full">12 week Intro to Kettlebells Program</li>
                        <li className="px-6 py-2 border-b border-gray-200 w-full">Sport Specific Advanced Training</li>
                        <li className="px-6 py-2 border-b border-gray-200 bg-gray-100 w-full pl-14">Golfers</li>
                        <li className="px-6 py-2 border-b border-gray-200 bg-gray-100 w-full pl-14">Runners</li>
                        <li className="px-6 py-2 border-b border-gray-200 bg-gray-100 w-full pl-14">Volleyball</li>

                    </ul>
                </div>

                <div className={"lg:flex lg:flex-row lg:justify-center "}>
                    <a className={"px-4"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call Us and get started! </button></a>
                    <a className={"px-4 pb-10"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                </div>


            </div>
            </Hero>



            <Footer/>
        </div>
    )
}

export default LargeGroupTraining